console.log( 'barba code running', typeof barba );
    console.log( 'gsap code running', typeof gsap );
    console.log( 'gsap code running', typeof gsap );

    // Show Secondary Footer
    const elSecondaryFooter = document.querySelector( '#wrapper-footer' );
    console.log( 'elSecondaryFooter', elSecondaryFooter );
    if( elSecondaryFooter ){
        elSecondaryFooter.classList.remove( 'd-none' );
        elSecondaryFooter.classList.add( 'd-block' );
        gsap.to( elSecondaryFooter, {opacity: 1, duration: 1} );
    }
    // Show Footer
    const elFooter = document.querySelector( '#wrapper-footer-full' );
    console.log( 'elFooter', elFooter );
    if( elFooter ){
        elFooter.classList.remove( 'd-none' );
        elFooter.classList.add( 'd-block' );
        gsap.to( elFooter, {opacity: 1, duration: 1} );
    }

    barba.hooks.before((data) => {
        console.log( 'before data', data );
        
        if( data.current.url.href.includes('wp-admin') ){
            barba.force(data.next.url.href);
            barba.destroy();
        }

        if( window.location.href.includes('.php') ){
            barba.force(window.location.href);
            barba.destroy();
        }

    });

    barba.hooks.enter((data) => {
        console.log('barba namespace', data.next.namespace);
    });

    barba.hooks.after((data) => {
        console.log( 'before data', data );
        
        if( data.current.url.href.includes('wp-admin') || data.current.url.href.includes('.php') ){
            barba.destroy();
        }

    });

    barba.init({
        debug: true,
        transitions: [
            // {

            //     name: 'home-transition',
            //     from: {
            //         namespace: [
            //             'home'
            //         ]
            //     }

            // },
            {
            name: 'default-transition',
            // from: {
            //     namespace: [
            //         'post-sample-page',
            //         'post-portfolio'
            //     ]
            // },
            // to: {
            //     namespace: [
            //         'post-sample-page',
            //         'post-portfolio'
            //     ]
            // },

            // before( data ){
            //     console.log( 'before data', data );
            //     if( window.location.href.includes('.php') ){
            //         barba.force(window.location.href);
            //     }

            //     if( data.current.url.href.includes('wp-admin') ){
            //         barba.force(data.next.url.href);
            //     }
            // },

            leave(data) {
                
                const isCurrentHome = data.current.namespace === 'home';
                const leaveTl = gsap.timeline({
                    // onComplete: () => console.log( 'done' )
                });

                const elNavWrapper = data.current.container.querySelector('.site #wrapper-navbar')
                leaveTl.add(
                    gsap.fromTo( elNavWrapper, {
                        // opacity: 1,
                        y: 0,
                    },{
                        // opacity: 0,
                        y: -72,
                        duration: 1,
                        ease: "power2.out"
                    })
                );
                leaveTl.add(
                    gsap.fromTo( elNavWrapper.nextElementSibling, {
                        opacity: 1,
                    },{
                        opacity: 0,
                        duration: 1,
                        ease: "power2.out"
                    })
                , "<" );
                leaveTl.add(
                    gsap.fromTo( data.current.container.querySelector( '#wrapper-footer-full' ), {
                        opacity: 1,
                    },{
                        opacity: 0,
                        duration: 1,
                        ease: 'power2.out'
                    })
                , 0 );
                leaveTl.add(
                    gsap.fromTo( data.current.container.querySelector( '#wrapper-footer' ), {
                        opacity: 1,
                    },{
                        opacity: 0,
                        duration: 1,
                        ease: 'power2.out'
                    })
                , 0 );

                return leaveTl.play();
            },

            enter(data) {
                console.log( 'enter', data );

                const isHome = data.next.namespace === 'home';

                const elNavWrapper = data.next.container.querySelector( '.site #wrapper-navbar' );
                const elWithBodyClass = data.next.container.querySelector( `[data-body-class]` );
                if( elWithBodyClass ){
                    const bodyClass = JSON.parse(elWithBodyClass.dataset.bodyClass);
                    const bodyClassStr = bodyClass.reduce( (initial, next) => `${initial} ${next}` );
    
                    
                    const elBody = document.querySelector( 'body' );
                    console.log( 'elBody', elBody );
                    console.log( 'bodyClassStr', bodyClassStr );
                    elBody.className = bodyClassStr;
                }

                const footerMain = data.next.container.querySelector( '#wrapper-footer-full' );
                const footerAdditional = data.next.container.querySelector( '#wrapper-footer' );

                gsap.set( elNavWrapper.nextElementSibling, {opacity: 0} );
                gsap.set( footerMain, {opacity: 0} );
                gsap.set( footerAdditional, {opacity: 0} );
                gsap.set( elNavWrapper, {y: -72} );

                typeof mountNavApp === 'function' && mountNavApp( { nextContainer: data.next.container });

                const elHomepageApp = data.next.container.querySelector( '#homepage-app' );
                if( elHomepageApp ){
                    typeof mountHomepageApp === 'function' && mountHomepageApp( { nextContainer: data.next.container });
                } else {
                    if( Array.isArray( window.scrollMagicControllers ) ){
                        scrollMagicControllers.map( controller => {
                            controller && controller.destroy !== undefined && controller.destroy();
                            controller = null;
                        } );
                    }
                }

                window.scrollTo(0, 0);

                window.addEventListener( 'navLoaded', () => {

                    var tlTransitionLogo = anime.timeline({
                        easing: 'easeOutExpo',
                        duration: 1
                    });

                    tlTransitionLogo
                    .add({
                        targets: '.transition-logo-wrapper svg .path-1',
                        strokeDashoffset: [anime.setDashoffset, 0],
                        easing: 'easeInOutSine',
                        duration: 1000,
                        // delay: function(el, i) { return i * 250 },
                        direction: 'alternate',
                        // loop: true
                    })
                    .add({
                        targets: '.transition-logo-wrapper svg .path-2',
                        strokeDashoffset: [anime.setDashoffset, 0],
                        easing: 'easeInOutSine',
                        duration: 500,
                        // delay: function(el, i) { return i * 250 },
                        direction: 'alternate',
                    }, 500)
                    .add({
                        targets: '.transition-logo-wrapper',
                        opacity: [0, 1],
                        easing: 'easeInOutSine',
                        duration: 750,
                    }, 0)
                    .add({
                        targets: '.transition-logo-wrapper',
                        opacity: [1, 0],
                        easing: 'easeInOutSine',
                        duration: 750,
                    }, 1000)
                    .add({
                        targets: '.transition-logo-wrapper',
                        scale: [1, .7],
                        easing: 'easeInOutSine',
                        duration: 1500,
                    }, 0);

                    tlTransitionLogo.finished.then( function(){

                        console.log( 'tlTransitionLogo finished' );

                        const eventBarbaEnter = new CustomEvent( 'barbaEnter', {
                            detail: {
                                container: data.next.container
                            }
                        } );
                        window.dispatchEvent( eventBarbaEnter );

                        if( typeof animatePortfolioPost !== 'undefined' ){
                            animatePortfolioPost();
                        }

                        const enterTl = gsap.timeline();

                        
                        
                        
                        enterTl.add(
                            gsap.fromTo( elNavWrapper.nextElementSibling, {
                                opacity: 0,
                            },{
                                opacity: 1,
                                duration: 2,
                                ease: "power2.out"
                            })
                        , );
                        enterTl.add(
                            gsap.fromTo( footerMain, {
                                opacity: 0,
                            },{
                                opacity: 1,
                                duration: 2,
                                ease: "power2.out"
                            })
                        , 0 );
                        enterTl.add(
                            gsap.fromTo( footerAdditional, {
                                opacity: 0,
                            },{
                                opacity: 1,
                                duration: 2,
                                ease: "power2.out"
                            })
                        , 0 );

                        enterTl.add(
                            gsap.fromTo( elNavWrapper, {
                                // opacity: !elHomepageApp ? 0 : 1,
                                y: -72,

                            },
                            {
                                // opacity: 2,
                                duration: !elHomepageApp ? 1 : 2,
                                y: 0,
                                ease: "power2.out"
                            })
                        );
                        
                        
                    } );


                }, { once: true } )
                
            },
            beforeEnter( data ){
                console.log( 'before enter beforeEnter' );
                
            },
            afterEnter( data ){
                
                // jQuery('div.wpcf7 > form').each(function() {
                //     jQuery(this).wpcf7InitForm();
                // });

                // initCustomJS?.({html: data?.next?.container});
            }
        }]
    });

    barba.hooks.enter((data) => {
        console.log( 'hooks.enter', data.next.namespace);
    });